.chartContainer {
  min-height: 330px;
  flex-direction: row;
  padding: 21px 13px 0 23px;

  .title {
    font-size: 17px;
  }

  .iconContainer {
    margin: 28px 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      height: 60px;
      margin-right: 14px;
      margin-top: 24px;
    }

    .learningTimeIcon {
      height: 60px;
      margin-right: 10px;
      margin-top: 10px;
    }

    .activeNumber {
      font-size: 40px;
      font-family: "CeraPRO", sans-serif;
      font-weight: bold;
      color: #474545;
      margin-top: 24px;
    }

    .totalLearningTimeText {
      padding-top: 30px;
    }
  }

  .text {
    font-size: 16px;
    color: #474545;
    font-weight: initial;
  }
}
