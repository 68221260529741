.headerContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.space {
  width: 100%;
}

.input {
  width: 245px;
}

.table {
  :global {
    .ant-table-filter-trigger-container {
      left: unset;
      right: 0;
    }
    .ant-table-cell {
      .ant-empty-normal {
        margin: 80px;
      }
    }
  }
}
