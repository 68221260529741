.chartContainer {
  height: 330px;
  display: flex;
  flex-direction: column;
  position: relative;

  h4 {
    margin: 20px 0 8px 23px;
  }
}
