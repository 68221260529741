@use "../../../styles/variables" as *;

.form {
  column-gap: 24px;
  width: 1056px;
  margin-left: 20px;

  & > :global(.ant-form-item) {
    display: flex;
    flex-direction: column;
    margin: 0;
  }

  :global {
    .ant-form-item-control {
      min-height: 56px;
    }

    .ant-form-item-control-input-content {
      > span, li {
        font-weight: 500;
        font-size: 16px;

        svg {
          width: 2em;
        }
      }
    }

    .ant-form-item-control .ant-radio-group {
      display: flex;

      > label {
        flex-grow: 1;
      }
    }
  }
  .minusIcon {
    margin-left: -16px;
  }

  .descriptionTextArea {
    textarea {
      height: 80px;
    }
    :global {
      .ant-form-item-control {
        height: 104px;
      }
    }
  }
}

.button {
  margin: 0 0 10px 0;
  padding-left: 0;
  color: $dark-blue;
}
