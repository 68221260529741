.iconWrapper {
  background: #f3f4ff;
  width: 247px;
  height: 247px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-bottom: 12px;
}

