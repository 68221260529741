.table {
  margin-top: 24px;

  :global {
    .ant-table-tbody > tr > td {
      &:last-child {
        padding-right: 16px !important;
      }

      &:first-child {
        padding-left: 8px !important;
      }
    }

    .ant-table-thead > tr > th:first-child {
      padding-left: 8px !important;
    }

    .ant-table-expanded-row-level-1 > .ant-table-cell {
      background-color: rgb(242, 243, 250) !important;
    }

    .ant-table-tbody > tr.ant-table-row-selected:hover > td {
      background-color: #e7e9f6;
    }
  }
}

.buttonGroup {
  margin-top: 10px;
  width: 100%;
  justify-content: flex-end;

  :global {
    .ant-btn {
      width: 90px;
    }
  }
}

.to {
  margin: 0 20px;
}

.modalContainer {
  position: relative;

  .loadingOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }
}

.dataInput {
  width: 130px;
}

.filter {
  :global {
    .ant-popover-inner-content {
      padding-left: 0;
      width: 454px;
    }

    .ant-popover-arrow {
      display: none;
    }
  }
}

.select {
  width: 200px !important;

  :global {
    .ant-select-selection-overflow {
      flex-wrap: nowrap !important;
      overflow-x: auto;
    }
  }
}
