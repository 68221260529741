.radioGroup {
  margin: 0 0 24px 12px;

  :global {
    .ant-radio-button-wrapper {
      width: 74px;
      text-align: center;
    }
  }
}
