@use "../../../styles/variables" as *;

.table {
  :global {

    .ant-table-tbody > tr > td {
      &:last-child {
        padding-right: 16px !important;
      }
    }

    .ant-table-expanded-row-level-1 > .ant-table-cell {
      background-color: rgb(242, 243, 250) !important;
    }

  }
}
