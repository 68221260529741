@use "../../../styles/variables" as *;

.searchForm {
  width: 800px;

  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
  
  .buttonGroup {
    width: 100%;
    justify-content: flex-end;

    :global {
      .ant-btn {
        width: 90px;
      }
    }
  }
}

.popover {
  left: 390px !important;
  
  :global {
    .ant-popover-inner-content {
      padding-left: 20px;
    }

    .ant-popover-arrow {
      display: none;
    }
  }
}