
.invoiceInput {
  width: 245px;
}

.tableRow:hover {
  cursor: pointer;
}

.action:hover {
  cursor: default;
}
