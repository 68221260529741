@use "../../styles/variables" as *;

.dropdownCheckbox {
  background-color: $white;

  /* options */
  > label {
    display: block;
    margin: 0 !important;
    padding: map-get($spacers, 2) $spacer;
  }

  :global {
    .ant-checkbox-wrapper {
      color: #848484;

      &-checked {
        color: $dark-blue;
        background-color: $pale-grey;
      }
    }
  }
}

.dropdownInput {
  display: flex;
  min-width: 5rem;
  align-items: center;
  justify-content: space-between;
  padding: 0 11px;
  border-radius: 4px;
  color: $greyish-brown;

  & > span:first-child {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .placeholder {
    color: rgba(0, 0, 0, 0.25);
  }
}

.overlay {
  padding-top: 0;
  max-height: calc((22px + 1rem) * 5);
  overflow-y: scroll;
  box-shadow: 3px 3px 12px 0 rgba(0, 35, 90, 0.16),
    0 0 3px 0 rgba(0, 35, 90, 0.15);

  :global {
    .ant-popover-inner-content {
      padding: 0;
    }
    .ant-popover-arrow {
      display: none;
    }
  }
}

.icon {
  font-size: 12px;
}
