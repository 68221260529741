.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 24px 0;
}

.icon {
  margin-right: 12px;
}

.label {
  font-size: 16px;
}

.value {
  font-size: 22px;
  font-weight: bold;
}
