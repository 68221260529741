@use "mixins";
@use "variables" as *;

@include mixins.font-face(CeraPRO, fonts/CeraPROThin, 200, normal, ttf);
@include mixins.font-face(CeraPRO, fonts/CeraPROThinItalic, 200, italic, ttf);
@include mixins.font-face(CeraPRO, fonts/CeraPROLight, 300, normal, ttf);
@include mixins.font-face(CeraPRO, fonts/CeraPROLightItalic, 300, italic, ttf);
@include mixins.font-face(CeraPRO, fonts/CeraPRORegular, normal, normal, ttf);
@include mixins.font-face(
                CeraPRO,
                fonts/CeraPRORegularItalic,
                normal,
                italic,
                ttf
);
@include mixins.font-face(CeraPRO, fonts/CeraPROMedium, 500, normal, ttf);
@include mixins.font-face(CeraPRO, fonts/CeraPROMediumItalic, 500, italic, ttf);
@include mixins.font-face(CeraPRO, fonts/CeraPROBold, bold, normal, ttf);
@include mixins.font-face(CeraPRO, fonts/CeraPROBoldItalic, bold, italic, ttf);

body {
  background-color: $white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "CeraPRO", sans-serif;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin: 0;
}

/**
 * <main> is the place to display contents.
 *   1. The first child of <main> is breadcrumb, which is used for navigation.
 *   2. The second child is the page information container i.e. Page in our terminology.
 *        In our design, all of  pages have the same layout. Design inspired from ant design card component.
 *   3. The third child is thr footer, which normally displays the copyright.
 */
main {
  > :nth-child(2) {
    margin: map-get($spacers, 2) 0 $spacer 0;
    min-height: calc(100vh - 170px);
  }
}

.page-container.ant-card {

  border-radius: 4px;
  border: solid 1px #e6eaf2; // 230 234 242,

  .ant-card-head {
    border-bottom: 1px solid $border-grey;

    &-title {
      font-size: 26px;
      line-height: 1.2;
      font-weight: bold;
      padding: 24px 0;
    }

    .ant-tabs-tab {
      padding: 0 0 16px 0;
    }

    .ant-btn {
      min-width: 92px;
    }
  }
}

h2 {
  @include mixins.Heading1;
  margin-bottom: 0;
}

h3 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 0;
  color: $greyish-brown;
}

h4 {
  font-size: 16px;
  font-weight: bold;
  color: $greyish-brown;
}

ul,
ol,
li {
  list-style: none;
}

img {
  max-width: 100%;
}

a {
  color: $dark-blue;

  &:active,
  &:focus,
  &:hover {
    outline: none;
    text-decoration: underline;
    color: $dark-blue;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

div.tooltip-inner {
  max-width: 350px;
}

textarea {
  resize: none;
}

input {
  border-radius: 4px;
  border: solid 1px $brown-grey-four;
  background-color: $white;
  padding: 1px 8px;
  font-size: 14px;
  width: 256px;

  &:focus {
    outline-color: $dark-blue;
  }
}

.react-resizable {
  position: relative;
  background-clip: padding-box;
}

.react-resizable-handle {
  position: absolute;
  right: -5px;
  bottom: 0;
  z-index: 1;
  width: 10px;
  height: 100%;
  cursor: col-resize;
}
