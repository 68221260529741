@mixin oval($color) {
  display: flex;
  align-items: center;

  &::before {
    display: inline-block;
    content: " ";
    width: 8px;
    height: 8px;
    border-radius: 4px;
    margin-right: 4px;
    background-color: #{$color};
  }
}

.completed {
  @include oval(#00a854);
}

.notCompleted {
  @include oval(#ff4d4f);
}
