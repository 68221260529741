@use "../../styles/variables" as *;
@use "../../styles/mixins";

.footer {
  @include mixins.Body-footer;
  padding: map-get($spacers, 4);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(0, 35, 90, 0.06), 0 1px 8px 0 rgba(0, 0, 0, 0.08);
}

.copyright {
  color: $greyish-brown;
  margin: 0;
}

.links {
  margin: 0;

  li {
    display: inline-block;
  }

  span {
    margin-left: 4px;
    margin-right: 4px;
  }

  .bold {
    font-weight: bold;
    margin: 0;
  }
}
