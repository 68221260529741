.radioGroup {
  :global {
    .ant-radio-button-wrapper {
      width: 174px;
      text-align: center;
    }
  }
}

.rangePicker {
  :global {
    .ant-picker-range-wrapper {
      position: relative;
      right: 102px !important;
      left: auto !important;
      top: 28px !important;
    }
  }
}

.rangeMenu {
  position: absolute;
  left: 100%;
  top: 0;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  height: 270px;
  width: 126px;
  background: white;

  :global {
    .ant-menu-item {
      margin-bottom: 4px !important;
    }

    .ant-menu-item-selected {
      background-color: white !important;
      font-weight: 600;
      color: #1c2154;
      font-size: 16px;
    }

    .ant-menu-item-active {
      background-color: white !important;
    }
  }
}

.datePickerContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .calendarState {
    padding: 2px 8px;
    background-color: #f3f4ff;
    border-radius: 6px;
    margin-top: 4px;
    margin-bottom: 8px;
    font-family: "CeraPRO", sans-serif;
    color: #1c2154;
  }
}

.dropDownIcon {
  color: #1c2154;
}
