@use "../../../styles/variables" as *;

.tab {
  :global {
    .ant-tabs-nav-operations {
      display: none !important;
    }
    .ant-tabs-nav-list {
      flex: 1 !important;
    }
    .ant-tabs-tab {
      flex: 1 !important;
      justify-content: center !important;
      background-color: #e3e3e8 !important;
    }
    .ant-tabs-tab-active {
      background-color: rgb(242, 243, 250) !important;
      border-bottom-color: rgb(242, 243, 250) !important;
    }
    .ant-tabs-tab + .ant-tabs-tab {
      margin-left: 0 !important;
    }
  }
}
