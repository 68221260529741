.formContainer {
  :global {
    .ant-form-item {
      margin-top: 4px;
      margin-bottom: 4px;
    }
  }
}

.inputContainter {
  display: flex;
  flex-direction: column;
}

.input {
  width: 256px;
}

.addBtn {
  margin-left: 0;
  padding-left: 0;
  color: #1c2154;
}

.deleteBtn {
  margin-top: 14px;
}
