.colContainer {
  position: relative;
  display: flex;
  flex-direction: column;
}

.rowContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.errorContainer {
  position: absolute;
  top: 100%;
  left: 0;
}

.error {
  font-size: 10px;
  color: red;
  white-space: nowrap;
}

.content {
  position: relative;
}

.loadingOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.form {
  margin-bottom: 0;
}

.buttonGroup {
  width: 100%;
  margin-top: 40px;
  justify-content: flex-end;
}

.required::before {
  content: "*";
  margin-right: 4px;
  font-size: 12px;
}

.required {
  margin-right: 8px;
}

.toast {
  margin-left: 16px;
  cursor: pointer;
}

.errorInput {
  border-color: red !important;
}

.popover {
  :global {
    .ant-popover-inner {
      background-color: white !important;
    }
    .ant-popover-arrow {
      right: 4px !important;
    }
    .ant-popover-arrow-content {
      background-color: white !important;
    }
    .ant-popover-inner-content {
      min-width: 400px;
      max-width: 550px;
    }
  }
}
