%notification-shared {
  width: 436px;
  border-radius: 4px;
  box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.08);
  :global(.ant-notification-notice-message) {
    font-weight: bold;
  }
}

// copied from Zeplin component Feedback
.success {
  @extend %notification-shared;
  border: solid 1px #cfefdf;
  background-color: #ebf8f2;
}

.error {
  @extend %notification-shared;
  border: solid 1px #ffa39e;
  background-color: #fff1f0;
}

.info {
  @extend %notification-shared;
  border: solid 1px #91d5ff;
  background-color: #e6f7ff;
}

.warning {
  @extend %notification-shared;
  border: solid 1px #ffe58f;
  background-color: #fffbe6;
}
