
.voucherAssignment {

  :global {
    .ant-table-tbody > tr > td:last-child {
      line-height: 0.8;
    }
    table tr td.ant-table-selection-column, table tr th.ant-table-selection-column {
      padding-left: 16px !important;
    }
  }
}

.sendEmailModal {
  width: 520px !important;
}
