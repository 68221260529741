@use "../styles/variables" as *;

.mainWrapper {
  display: flex;
  flex-grow: 1;
  margin-top: $header-height;
  min-height: 0;
}

.modal {
  width: 480px !important;

  :global {
    .ant-modal-confirm-body > .anticon {
      padding: 32px 0 14px 32px;
      font-size: 24px;
    }

    .ant-modal-confirm-content {
      font-size: 16px;
      padding: 4px 24px 0 0 !important;
    }

    .ant-modal-confirm-title {
      font-weight: bold;
      font-size: 18px;
      border-bottom: none !important;
      padding: 32px 24px 0 0 !important;
    }

    .ant-modal-confirm-btns {
      padding: 0 32px 24px 0 !important;
    }

    .ant-btn-primary {
      color: $white !important;
    }
  }
}