@use "../../../styles/variables" as *;

.tooltip {
  background: none;
  border: none;

  :global {

    .ant-input {
      background: none;
      color: $white;

      &::selection {
        color: $black;
        background: $white;
      }
    }

    .ant-tooltip-inner {
      min-width: 200px;
      background-color: $white;
    }

    .ant-form-item-control-input-content {
      > span, li {
        font-weight: 500;
        font-size: 16px;
      }
    }
  }
}