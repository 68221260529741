@use "../../../../../styles/variables" as *;

.searchForm {
  width: 1000px;
  justify-content: space-between;

  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }

  .buttonGroup {
    width: 100%;
    justify-content: flex-end;

    :global {
      .ant-btn {
        width: 90px;
      }
    }
  }
}

.popover {
  left: 310px !important;

  :global {
    .ant-popover-inner-content {
      padding-left: 20px;
    }

    .ant-popover-arrow {
      display: none;
    }
  }
}