.dropdownCheckbox {
  background-color: white;
}

.dropdownInput {
  display: flex;
  width: 250px;
  align-items: center;
  justify-content: space-between;
  padding: 0 11px;
  border-radius: 4px;
  color: #474545;

  .placeholder {
    color: rgba(197, 197, 197);
  }
}

.overlay {
  padding-top: 0;
  box-shadow: 3px 3px 12px 0 rgba(0, 35, 90, 0.16),
    0 0 3px 0 rgba(0, 35, 90, 0.15);

  :global {
    .ant-popover-inner-content {
      padding: 0;
    }
    .ant-popover-arrow {
      display: none;
    }

    .ant-radio-input {
      width: 100px;
    }
  }
}

.icon {
  font-size: 12px;
}

.content {
  background-color: white;
  padding: 20px;
  width: 500px;

  .text {
    margin-right: 10px;
    font-size: 14px;
  }

  .container {
    position: relative;
    display: flex;
    align-items: center;
  }

  .form {
    margin-bottom: 0;
  }

  .errorContainerWithOn {
    position: absolute;
    top: 100%;
    left: 26px;
    width: 222px;
  }

  .errorContainer {
    position: absolute;
    top: 100%;
    width: 222px;
  }

  .error {
    font-size: 10px;
    color: red;
  }

  .errorSelect {
    :global {
      .ant-select-selector {
        border-color: red !important;
      }
    }
  }

  .errorPicker {
    border-color: red !important;
  }
}

.buttonGroup {
  width: 100%;
  margin-top: 40px;
  justify-content: flex-end;

  :global {
    .ant-btn {
      width: 90px;
    }
  }
}
