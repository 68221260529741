@use "../../../styles/variables";

.uploadDragIcon {
  margin: 12px 0;
  font-size: 32px;
  color: variables.$brown-grey;
}

.uploadText {
  font-size: 16px;
}

.uploadLink {
  margin-bottom: 16px;
}

.uploadHint {
  color: variables.$brown-grey;
}

.templateLink {
  padding: 0;

  &:hover {
    color: variables.$vivid-blue;
  }
}
