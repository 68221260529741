@keyframes shake {
  0% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(-10deg);
  }
}

.popover {
  :global {
    .ant-popover-inner-content {
      padding: 0;
    }
  }
}
th:not([title]) {
  cursor: pointer;

  &:hover {
    background-color: #e7e9f6;
  }
}

.menu {
  :global {
    .ant-menu-item {
      margin: 0;
      &:not(:last-child) {
        margin: 0;
      }

      &:hover {
        background-color: #e7e9f6;
      }
    }

    .ant-menu-item-selected {
      background-color: #e7e9f6 !important;
    }
  }
}

.email {
  width: 245px;
}

.alarm {
  margin-right: 10px;
  cursor: pointer;
  fill: #1c2154;
}

.shake {
  animation: shake 0.2s infinite;
  fill: red;
}

.scheduledExportText {
  color: #1c2154;
  cursor: default;
}

.redScheduledExportText {
  color: red;
}

.delete {
  background-color: white;
  color: blue;
}

.scheduledPopover {
  :global {
    .ant-popover-inner {
      background-color: white !important;
    }

    .ant-popover-arrow-content {
      background-color: white !important;
    }

    .ant-popover-inner-content {
      padding: 6px 8px;
      min-width: 240px;
    }
  }

  .deleteBtn {
    padding: 0;
    margin-left: 4px;
    height: 24px;
  }
}
