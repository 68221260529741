@use "../../styles/variables" as *;

.confirm {
  min-width: 520px;

  :global {
    .ant-modal-body {
      padding: 0;
    }

    .ant-modal-close-x {
      color: $dark-blue;
    }

    .ant-modal-confirm-title {
      font-weight: bold;
      font-size: 18px;
      border-bottom: 1.5px solid $dark-blue;
      padding: 14px 24px;
    }

    .ant-modal-confirm-content {
      font-size: 16px;
      padding: 14px 24px 24px 24px;
      min-height: 5rem;
    }

    .ant-modal-confirm-btns {
      padding: 10px 16px;
      margin-top: 0;
    }
  }
}

.container {
  :global {
    .ant-modal-content {
      width: 480px;
    }
  }
}
