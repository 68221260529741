@use "../../../../styles/variables" as *;

.tab {

  .assignTable {
    :global {
      .ant-table-tbody > tr > td:last-child {
        line-height: 0.8;
      }

      table tr td.ant-table-selection-column, table tr th.ant-table-selection-column {
        padding-left: 16px !important;
      }
    }
  }

  :global {
    .ant-tabs-nav {
      margin: 24px 0 0 0;
    }

    .ant-tabs-tab-btn {
      font-weight: normal;
      color: $greyish-brown;
    }
  }
}
