@use "../../styles/variables" as *;

.container {
  border-radius: 4px;
  border: 1px solid $brown-grey-four;
  padding: 8px;
  min-height: 68px;

  .input {
    margin-top: 8px;
    border-radius: 16px;
    display: inline-block;
    min-width: 180px;
    max-width: max-content;
    line-height: 1;
  }

  .plusTag {
    margin-top: 8px !important;
    border: 1px dashed $greyish-brown;
    color: $greyish-brown;
    background-color: white;
  }
  :global {
    .ant-tag {
      margin-top: 8px;
    }
  }
}
