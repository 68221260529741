.iconWrapper {
  background: #f3f4ff;
  width: 90px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-bottom: 12px;

  svg {
    width: 60px;
    height: 50px;
  }
}
