.content {
  margin: 0 0 25px;

  .email {
    width: 245px;
  }

  .alarm {
    margin-right: 10px;
    cursor: pointer;
  }

  .scheduledExportText {
    color: #1c2154;
    cursor: default;
  }

  .delete {
    background-color: white;
    color: blue;
  }
}

.scheduledPopover {
  :global {
    .ant-popover-inner {
      background-color: white !important;
    }

    .ant-popover-arrow-content {
      background-color: white !important;
    }

    .ant-popover-inner-content {
      padding: 6px 8px;
      min-width: 360px;
    }
  }

  .deleteBtn {
    padding: 0;
    margin-left: 4px;
    height: 24px;
  }
}
